.ParentContainer {
    display: flex;
    flex:1;
    flex-direction: row;
    margin-left: 28px;
    margin-right: 28px;
}
.container {
    display: flex;
    flex:7;
    flex-direction: row;
    order:2;
}
.containerPro {
    display: flex;
    flex:7;
    flex-direction: column;
    margin-top:10px;
}
.leftContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-top: 20px;
    flex: 1;
}
.rightContainer {
    display: flex;
    flex:3;
    flex-direction: column;
}
.backgroundHeader {
    background-color: darkgreen;
    height: 150px;
    border: 5px solid greenyellow;
    border-bottom-left-radius: 30%;
    border-bottom-right-radius: 30%;
    margin-top: 16px;
    font-size: 32;
    font-weight: 700;
    color: white;
    text-align: center;
    padding-top: 20px;
}
.avatar {
    z-index: 1;
    margin-top: -100px;
    width:100%;
    text-align: center;
}
.personInfo {
    display: flex;
    flex-direction: column;
}
.personMajor {
    display: flex;
    flex-direction: column;
}

h3 {
    margin-left: -8px;
    margin-top: 25px;
    margin-left: 0px;
}
.iconCV {
    width: 45px;
    height: 45px;
    flex:1;
}
.details {
    display: flex;
    flex:1;
    flex-direction: row;
    margin-bottom: 10px;
}
.itemContent {
    height: 45px;
    flex:6;
    padding-left: 10px;
}
.eventName {
    float:left;
    margin-left: 20px;
}
.periodTime {
    text-align: right;
    float: right;
}
li {
    margin-bottom: 10px;
}
.contentWithHtml {
    padding-top:25px;
    padding-left:78px; 
    clear:'both';
    font-size:14px;
}
ul {
    list-style: none;
}
.contentWithHtml ul li{
    padding-left: 30px; /* Adjust left padding to create space for the icon */
    background-image: url('check-circle-solid.png'); 
    background-size: 20px 20px; /* Adjust size of the SVG image */
    background-repeat: no-repeat; /* Prevent the image from repeating */
    background-position: left center; /* Position the image on the left */
    font-size: 16px;
}
.contentWithHtml ul{
    margin-left: -35px;
    padding-left: 0px;
}

.options {
    margin-top: 10px;
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: column;
    order:1;
}
.option-1 {
    width: 100px;
    height: 100px;
    border-radius: 100%;
}
.GeneralInfoContainer {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.GeneralInfo p {
    font-size: 24px;
    padding:0px;
    margin:10px 37px;
}
.Profile p {
    font-size: 24px;
    margin-left: 87px;
    text-align: justify;
}
.Education table {
    font-size: 24px;
    margin-left: 87px;
    border-collapse: collapse;
}
.Education table tr td {
    border: 2px solid black;
    padding-left: 2px;
}
.Employment p {
    font-size: 24px;
    margin-left: 90px;
}
.Employment h3 {
    margin-left: 87px;
}
.Profile, .Education, .Employment {
    width: 90%;
}
@media(max-width: 768px){
    .container {
        display: flex;
        flex:1;
        flex-direction: column;
    }
    .eventDetail {
        display: flex;
        flex-direction: column;
    } 
    .periodTime {
        margin-left: 22px;
        width: 100%;
        text-align: left;
        margin-top: 10px;
    }
    .GeneralInfoContainer {
        width: 90%;
        display: flex;
        flex-direction: column;
    }
    .ParentContainer {
        display: flex;
        flex:1;
        flex-direction: column;
        margin-left: 28px;
        margin-right: 28px;
    }
    .options {
        margin-top: 10px;
        display: flex;
        flex: 1;
        align-items: center;
        flex-direction: row;
    }    
}