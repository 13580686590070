.containerExamInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f0f2f5;
  }
  
  .greeting {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .greeting span {
    color: #ff7e1a; /* Màu chữ đậm như trong hình */
    font-weight: bold;
  }
  
  .form-container {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .ant-select {
    width: 300px;
    margin-right: 20px;
  }
  
  .create-button {
    background-color: #ff7e1a; /* Màu nền của nút */
    border: none;
    color: white;
    font-weight: bold;
    border-radius: 4px;
    padding: 5px 15px;
    display: flex;
    align-items: center;
  }
  
  .create-button .anticon {
    margin-right: 5px;
  }
  
  @media (max-width: 768px) {
    .form-container {
      flex-direction: column;
    }
  
    .ant-select {
      margin-bottom: 10px;
      margin-right: 0;
    }
  }