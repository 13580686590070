/* JobSearchBar.css */
.job-search-bar {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    background-color: #f8f8f8;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
  }
  
  .job-search-bar .input-field {
    flex: 1;
    margin-right: 10px;
    height: 40px;
    margin-top: -3px;
    border: none;
  }
  
  .job-search-bar .dropdown {
    flex:1;
    margin-right: 10px;
  }
  
  .job-search-bar .search-button {
    background-color: #ff5a00;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 5px 15px;
    cursor: pointer;
    height: 40px;
    margin-top: 0px;
  }
  
  .job-search-bar .search-button:hover {
    background-color: #e14a00;
  }

  /* JobSearchBar.css */
@media (max-width: 768px) {
    .job-search-bar {
      flex-direction: column;
      align-items: stretch;
    }
  
    .job-search-bar .input-field, .job-search-bar .dropdown, .job-search-bar .search-button {
      margin: 5px 0;
    }
    .job-search-bar .input-field .search-button {
      width: 100%;
    }
    .job-search-bar .dropdown {
      width: 95%;
    }
  }
  
  