/* Pagination.css */
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background-color: #f0f2f5;
  }
  
  .pagination button, .pagination span {
    margin: 0 5px;
    padding: 5px 10px;
    border: none;
    background: none;
    cursor: pointer;
    color: #333;
  }
  
  .pagination .active {
    background-color: #ff5a00;
    color: #fff;
    border-radius: 100%;
  }
  
  .pagination .dots {
    pointer-events: none;
  }
  
  .pagination .arrow {
    color: #ff5a00;
  }
  