.whole-question {
  background-color: #f0f2f5;
  padding: 10px 30px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.question-container {
    background-color: #f0f2f5;
    padding: 10px 30px;
    display: flex;
    flex:2;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .question-header {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .question-content {
    margin-bottom: 20px;
  }
  
  .answer-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  
  .answer-item {
    background-color: white;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
    margin-right: 10px;
    display: flex;
    flex: 1 1 40%;
    align-items: center;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .answer-item:hover {
    border: 1px solid #ff7e1a;
  }
  .imgQuestion {
    object-fit:'contain';
    max-width:100%;
    padding-right:30px; 
    flex:1;
  }
  .answer-item input {
    margin-right: 10px;
  }
  
  @media (max-width: 768px) {
    .whole-question {
      flex-direction: column;
      padding:5px 0px;
    }
    .answer-item {
      flex-direction: row;
      align-items: flex-start;
      flex: 1 1 100%;
    }
    img {
      max-width:84%;
      padding-right:30px;
      padding-left:30px; 
      flex:1;
    }
    .answer-item input {
      margin-bottom: 5px;
    }
  }
  