.custom-modal .ant-modal-content {
    display: inline-block; /* Inline-block allows the content to determine the width */
    max-width: 100%; /* Prevents the modal from exceeding the viewport width */
  }
  
  .custom-modal .ant-modal-body {
    padding: 0; /* Adjust padding as needed */
    margin: 0; /* Adjust margin as needed */
  }
  .LoadingContainer {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    background-color: beige;
  }
  .modal-content {
    padding: 16px; /* Adjust the padding to suit your needs */
  }
  
  /* Optionally, set max height to handle overflow, if necessary */
  .custom-modal .ant-modal {
    max-height: 90vh; /* Prevents the modal from exceeding the viewport height */
    overflow-y: auto; /* Adds scroll if content exceeds max-height */
}
  