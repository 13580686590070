.login-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 100px 100px 100px 100px;
    background-color: #f5f5f5;
    min-height: 100vh;
  }
  
  .login-form {
    width: 40%;
  }
  
  .illustration {
    width: 50%;
  }
  
  .ant-btn-primary {
    background-color: #f57224;
    border-color: #f57224;
  }
  
  @media (max-width: 768px) {
    .login-container {
      flex-direction: column;
      align-items: center;
    }
    .login-form, .illustration {
      width: 100%;
      margin-bottom: 20px;
    }
  }
  