/* JobSearchHeader.css */
.job-search-header {
    text-align: center;
    padding: 20px;
    background-color: #f0f2f5;
    border-radius: 4px;
  }
  
  .job-search-header h1 {
    font-size: 24px;
    font-weight: bold;
    color: #333;
  }
  
  .job-search-header h1 .highlight {
    color: #ff5a00;
  }
  
  .job-search-header p {
    font-size: 16px;
    color: #666;
  }
/* JobSearchHeader.css */
@media (max-width: 768px) {
    .job-search-header {
      padding: 10px;
    }
  
    .job-search-header h1 {
      font-size: 20px;
    }
  
    .job-search-header p {
      font-size: 14px;
    }
  }
    