h2 {
  border: unset;
  text-align: unset;
  padding: unset;
  margin: unset;
}
.RegisterHeader {
    text-align: left;
    color: #ff4d4f;
  }
  
  .ant-form-item {
    margin-bottom: 16px;
  }
  
  .ant-upload-list-picture .ant-upload-list-item-thumbnail {
    width: 100%;
  }
  
.ButtonUpload {
    height: 110px;
}
  @media only screen and (max-width: 768px) {
    .ant-form-item {
      margin-bottom: 12px;
    }
  }
  