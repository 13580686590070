.container {
    display: flex;
    height: 100vh;
    flex-direction: row;
}
.leftside {
    width: 50%;
}
.rightside {
    width: 50%;
    height: 80vh;
    background-color: blanchedalmond;
}