.contactlist {
    width: 300px;
    background-color: #f0f2f5;
    padding: 16px;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .header h3 {
    margin: 0;
  }
  
  .headeractions {
    display: flex;
    align-items: center;
  }
  
  .ellipsisicon {
    font-size: 24px;
    margin-left: 8px;
    cursor: pointer;
  }
  
  .onlinestatus {
    width: 10px;
    height: 10px;
    background-color: green;
    border-radius: 50%;
    display: inline-block;
    margin-left: 8px;
  }
  .idlestatus {
    width: 10px;
    height: 10px;
    background-color: orange;
    border-radius: 50%;
    display: inline-block;
    margin-left: 8px;
  }
  .fullHeightList {
    height: 100vh; /* Set the height to full viewport height */
    overflow-y: auto; /* Enable vertical scrolling */
  }
  .extra-info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  
  .updated-at {
    font-size: 12px;
    color: #888;
  }
  
  .unread-count {
    margin-top: 4px;
  }
  
  