.chat-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100vh;
    background-color: #f0f2f5;
  } 
  .chat-header {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: white;
    border-bottom: 1px solid #e8e8e8;
  } 
  .chat-header-info {
    flex-grow: 1;
    margin-left: 10px;
  }
  
  .chat-header-info h3 {
    margin: 0;
    font-size: 16px;
  }
  
  .chat-header-info span {
    font-size: 12px;
    color: #888;
  }
  
  .chat-header-actions > * {
    margin-left: 15px;
    font-size: 20px;
    cursor: pointer;
  }
  .userInfo {
    display: flex;
    flex-direction: row;
  }
  .chat-messages {
    flex-grow: 1;
    padding: 20px;
    overflow-y: auto;
    background-color: #e5ddd5;
  }
  
  .chat-message {
    display: flex;
    margin-bottom: 10px;
  }
  
  .chat-message.sent {
    align-items: flex-end;
    flex-direction: column;
  }
  
  .chat-message.received {
    align-items: flex-start;
    flex-direction: column;
  }
  
  .message-content {
    max-width: 60%;
    padding: 10px;
    border-radius: 10px;
    background-color: white;
    position: relative;
  }
  
  .chat-message.sent .message-content {
    background-color: #dcf8c6;
  }
  
  .message-time {
    display: block;
    font-size: 12px;
    color: #888;
    text-align: right;
    margin-top: 5px;
  }
  
  .chat-input {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: white;
    border-top: 1px solid #e8e8e8;
  }
  
  .chat-input > * {
    margin-right: 10px;
    font-size: 20px;
    cursor: pointer;
  }
  
  .ant-input {
    flex-grow: 1;
    border-radius: 20px;
  }
  