.exam-container {
    background-color: #f0f2f5;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .exam-header {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .exam-subheader {
    color: #333;
    font-weight: 700;
    margin-bottom: 20px;
  }
  
  .exam-details {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(30, 25, 25, 0.1);
    width: 100%;
    max-width: 800px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .detail-item {
    display: flex;
    align-items: center;
  }
  
  .detail-item span {
    margin-left: 8px;
    font-weight: bold;
  }
  
  .submit-button {
    background-color: #ff7e1a;
    border: none;
    color: white;
    font-weight: bold;
    border-radius: 4px;
    padding: 5px 15px;
    display: flex;
    align-items: center;
  }
  
  .submit-button .anticon {
    margin-right: 5px;
  }
  
  @media (max-width: 768px) {
    .exam-details {
      flex-direction: column;
    }
  
    .detail-item {
      margin-bottom: 10px;
    }
  }
  