.item {
    width: 100vw;
    height: 100vh;
    /*background-color: darkblue;*/
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    color:darkblue;
    text-align: center;
    background-image: url("./foldedimg1.jpg");
    background-size: cover;
    background-repeat: no-repeat;
}