.book-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
  }
  .book-container {
    display: flex;
    flex-direction: column;
    padding-left: 100px;
    padding-right: 100px;
  }
  .price {
    color: #000;
  }
  .bookItem {
    height: 400px;
  }
  .bookImage {
    width: 300px;
    height: 300px;
    background-color: #f5f7fa;
  }
  .bookRow {
    margin-left:37px; 
    margin-right:13px;
    margin-top:15px;
    display: flex;
    height: fit-content;
    background-color: #f5f7fa;
    flex-direction: row;
    justify-content: space-between;
  }
  .bookImage .ant-card-cover {
    margin-bottom: 8px; /* Adjust this value */
  }
  
  .bookImage .ant-card-head {
    margin-top: -8px; /* Negative margin to pull title closer */
  }
  .bookImage .ant-card-body {
    padding: 0px 5px 0px 5px; /* Negative margin to pull title closer */
  }