/* Navbar.css */
.navContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #fff;
}
.menuItem {
  margin: 5px 5px;
  font-weight: 700;
}
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #fff;
  }
  .menu-icon {
      margin: 0px;
      padding:0px;
      display: none;
  }
  .navbar .logo-section img {
    margin: 0 10px;
  }
  
  .link-section {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  
  .navbar .link-section img {
    margin-left: 20px;
  }
  .navbar .link-section a {
    margin-left: 20px;
    text-decoration: none;
    font-weight: bold;
  }
  a:hover {
    cursor: pointer;
  }
  .user-profile {
    display: flex;
    margin-left: 20px;
    align-items: center;
    background-color: #ff7e1a; /* Màu nền giống trong hình */
    padding: 2px 10px;
    border-radius: 15px; /* Bo tròn góc */
  }
  
  .user-profile img {
    border-radius: 50%;
    margin-left: 10px;
  }
  /* Navbar.css */
@media (max-width: 768px) {
    .navbar {
      flex-direction: row;
      align-items: flex-start;
      padding:5px 0px;
    }
    .navbar .logo-section img {
      padding-left: 2px;
      margin-right: 0px;
      padding-right: 0px;
    }
    .navbar .menu-icon img {
      padding-left: 2px;
      margin-right: 18px;
      padding-right: 0px;
    }
    .navbar .logo-section, .navbar .link-section {
      width: 100%;
      justify-content:center;
      margin-bottom: 10px;
    }
    .link-section a {
      margin-left: 0;
      margin-right: 10px;
    }
    .link-section.show {
      display: flex;
      align-items: flex-end;
      text-align: right;
    }
    .menuItem img {
      padding-right: 5px;
    }
    .user-profile {
      display: flex;
      margin-left: 46px;
      margin-top: 10px;
      align-items: center;
      background-color: #ff7e1a; /* Màu nền giống trong hình */
      padding: 2px 10px;
      border-radius: 15px; /* Bo tròn góc */
    }
    .menu-icon {
      display: block;
    }
    .link-section {
      display: none;
      flex-direction: column;
      width: 100%;
      padding: 0;
    }
    .navContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
    .menuItem {
      text-align:right;
      padding-right: 10px;
      font-weight: 700;
    }
  }
  