.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px 100px 10px 100px;
    background-color: #f5f5f5;
  }
  
  .logos {
    display: flex;
    gap: 15px;
  }
  
  .text-container {
    color: #f57224;
    font-size: 26px;
    font-weight: bold;
  }
  
  @media (max-width: 768px) {
    .header-container {
      flex-direction: column;
      align-items: flex-start;
    }
    .text-container {
      margin-top: 10px;
      text-align: center;
      font-size: 16px;
    }
  }
  