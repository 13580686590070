.RootContainer {
    width: 1920px;
    height: 1080px;
    background: #F1F3F5;
    top: 169px;
    left: 176px;
}

.TopLeftContainer {
    position: absolute;
    width: Hug (373.24px);
    height: Hug (52px);
    top: 54px;
    left: 315px;
    gap: 24px
}

.Header {
    position: absolute;
    /*width: 437px;*/
    width: 480px;
    height: 61px;
    top: 50px;
    left: 1169px;
    color: var(--orange-orange-500, #F26D21);
    /*leading-trim: both;
    text-edge: cap; */
    font-family: Roboto;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.Login {
    position: absolute;
    width: Hug (500px);
    height: Hug (452px);
    top: 276px;
    left: 315px;
    gap: 16px;
}

.LoginTop {
    width: Hug (500px);
    height: Hug (261px);
    gap: 24px;

}
.LoginTopInside1 {
    width: Fixed (500px);
    height: Hug (71px);
    gap: 12px;
    margin-bottom: 20px;

}
.LoginTopInside1Text {
    width: Hug (55px);
    height: Hug (11px);
    gap: 2px;

}
.LoginTopInside1TextData {
    width: 47px;
    height: 11px;
    font-family: Roboto;
    font-size: 20px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
}
.LoginTopInside1TextDataRequired {
    width: 6px;
    height: 6px;
    color: #FF4747;
    font-family: Roboto;
    font-size: 20px;
    font-weight: 600;
}
.LoginTopInside1Dropdown {
    width: Fixed (500px);
    height: Fixed (48px);
    gap: 8px
}
.LoginTopInside1DropdownTop {
    /*width: Fill (500px);
    height: Fill (48px);*/
    position: relative;
    width: 500px;
    height: 48px;
    padding: 0px, 12px, 0px, 16px;
    border-radius: 8px;
    /*justify: space-between;*/
    background: #FFFFFF;
    border: 1px solid #F26D21;
}
.LoginTopInside1DropdownTop::before {
    position: absolute;
    content: url('./Heart.svg');
    right: 15px;
    top: 10px;
    pointer-events: none;
  }
  
  .LoginTopInside1DropdownTop::after {
    position: absolute;
  }

select {
    appearance: none;
    /* safari */
  -webkit-appearance: none;
  /* other styles for aesthetics */
    width: 500px;
    height: 48px;
    border-radius: 8px;
    /*justify: space-between;*/
    background: #FFFFFF;
    border: 1px solid #F26D21;
    padding-left: 15px;
}
.LoginTopInside1DropdownBottom {
    width: Fixed (350px);
    height: Hug (194px);
    padding: 9px, 0px, 9px, 0px;
    border-radius: 6px;
    background: #FFFFFF;
    box-shadow: 0px 0px 6px 0px #E7E9F2;
}
.DropdownLine {
    width: 500px;
    height: 48px;
    padding: 10px, 12px, 10px, 12px;
    gap: 24px;
    background: #FEF1E9;
    box-shadow: 0px 0px 6px 0px #E7E9F2;
    box-shadow: 0px 0px 6px 0px #E7E9F2;
}
.DropdownLineText {
    width: 39px;
    height: 10px;
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #F26D21;
}
.LoginTopInside1Input {
    width: 509px;
    height: 48px;
    padding-left: 15px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    gap: 10px;
    color: #F26D21;
    border: 1px solid #F26D21;
}
.LoginTopInside1InputForIpad {
    width: 350px;
    height: 48px;
    padding-left: 15px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    gap: 10px;
    color: #F26D21;
    border: 1px solid #F26D21;
}

.LoginBottom {
    width: Hug (500px);
    height: Hug (175px);
    gap: 16px;

}
.myButton {
    margin-top: 20px;
    width: 500px;
    height: 48px;
    /*padding: 10px, 24px, 10px, 24px;*/
    border-radius: 8px;
    gap: 4px;
    color: #FFFFFF;
    font-family: Roboto;
    font-size: 18px;
    font-weight: 600;
    background-color: #F26D21;
    border: 1px solid #F26D21
}
.LoginImage {
    position: absolute;
    width: 580px;
    height: 341px;
    top: 332px;
    left: 1025px;

}

.LoginText {
    position: absolute;
    width: 173px;
    height: 42px;
    top: 202px;
    left: 315px;
    font-family: Roboto;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: var(--orange-orange-500, #F26D21);
}