.myCustomButton {
  margin:5px 69px 5px 59px;
}
.loadingdiv3 {
  width:300px;
  height:300px;
  border-radius:100%;
  background: linear-gradient(45deg, #F26D21, #F26D21 100%), linear-gradient(135deg, #F26D21, #F26D21), linear-gradient(225deg, #F26D21, #F26D21) , linear-gradient(225deg, #F26D21, #F26D21);
  background-size: 50% 50%;
  background-position: 0% 0%, 0% 100%, 100% 0%, 100% 100%;
  background-repeat: no-repeat;
  display:flex;
  justify-content:center;
  align-items:center;
  font-size:30px;
  font-weight:700;
  color:white
}
.loadingdiv2 {
  width:300px;
  height:300px;
  border-radius:100%;
  background: linear-gradient(45deg, #F26D21, #F26D21 100%), linear-gradient(135deg, #F26D21, #F26D21), linear-gradient(225deg, cyan, cyan) , linear-gradient(225deg, #F26D21, #F26D21);
  background-size: 50% 50%;
  background-position: 0% 0%, 0% 100%, 100% 0%, 100% 100%;
  background-repeat: no-repeat;
  display:flex;
  justify-content:center;
  align-items:center;
  font-size:30px;
  font-weight:700;
  color:blue
}
.loadingdiv1 {
  width:300px;
  height:300px;
  border-radius:100%;
  background: linear-gradient(45deg, #F26D21, #F26D21 100%), linear-gradient(135deg, #F26D21, #F26D21), linear-gradient(225deg, cyan, cyan) , linear-gradient(225deg, cyan, cyan);
  background-size: 50% 50%;
  background-position: 0% 0%, 0% 100%, 100% 0%, 100% 100%;
  background-repeat: no-repeat;
  display:flex;
  justify-content:center;
  align-items:center;
  font-size:30px;
  font-weight:700;
  color:blue
}
.loadingdiv0 {
  width:300px;
  height:300px;
  border-radius:100%;
  background: linear-gradient(45deg, #F26D21, #F26D21 100%), linear-gradient(135deg, cyan, cyan), linear-gradient(225deg, cyan, cyan) , linear-gradient(225deg, cyan, cyan);
  background-size: 50% 50%;
  background-position: 0% 0%, 0% 100%, 100% 0%, 100% 100%;
  background-repeat: no-repeat;
  display:flex;
  justify-content:center;
  align-items:center;
  font-size:30px;
  font-weight:700;
  color:blue
}

.loadingdiv {
  width:300px;
  height:300px;
  border-radius:100%;
  background: linear-gradient(45deg, cyan, cyan 100%), linear-gradient(135deg, cyan, cyan), linear-gradient(225deg, cyan, cyan) , linear-gradient(225deg, cyan, cyan);
  background-size: 50% 50%;
  background-position: 0% 0%, 0% 100%, 100% 0%, 100% 100%;
  background-repeat: no-repeat;
  display:flex;
  justify-content:center;
  align-items:center;
  font-size:30px;
  font-weight:700;
  color:blue
}
@media(max-width:768px) {
  .myCustomButton {
    margin:5px 39px 10px 29px;
  }
}