/* Footer.css */
.footer {
    text-align: center;
    padding: 10px 0;
    background-color: #f0f2f5;
    color: #888;
    font-size: 14px;
    background-color: #fff;
  }
  
  .footer a {
    color: #888;
    text-decoration: none;
  }
  
  .footer a:hover {
    text-decoration: underline;
  }
/* Footer.css */
@media (max-width: 768px) {
    .footer {
      font-size: 12px;
    }
  }
    