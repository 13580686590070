/* src/components/ReportForm.css */

@media (max-width: 600px) {
    .container {
      padding: 10px;
    }
    .form-item {
      margin-bottom: 10px;
    }
  }
  