.question {
    border: 2px solid grey;
    margin: 10px 50px 10px 50px;
    padding: 5px 5px 5px 10px;
}
h2 {
    border: 2px solid grey;
    margin: 10px 50px 10px 50px;
    padding: 5px 5px 5px 10px;
}

h3 {
    margin: 10px 50px 10px 50px;
    padding: 5px 5px 5px 10px;
}

.ant-radio-inner{
    border: 1px solid black!important;
}

.ant-radio-inner:after{
    border: 1px solid black!important;
}