/* JobCard.css */
.job-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px; /* Khoảng cách giữa các thẻ công việc */
    padding: 16px;
  }
  
  @media (max-width: 768px) {
    .job-list {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  
  @media (min-width: 769px) and (max-width: 1024px) {
    .job-list {
      grid-template-columns: repeat(2, 1fr);
    }

  }
  
  .job-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px;
    margin: 8px;
    border: 1px solid #e8e8e8;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .job-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .companyLogo {
    padding: 5px 5px;
  }
  .job-card-header-left {
    display: flex;
    align-items: center;
  }

  .job-card-title {
    font-size: 16px;
    font-weight: bold;
  }
  
  .job-card-company {
    color: #888;
  }
  
  .job-card-requirements {
    margin: 8px 0;
    color: #333;
  }
  
  .job-card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .job-card-contact {
    display: flex;
    align-items: center;
    color: #888;
  }
  
  .job-card-contact img {
    margin-right: 8px;
  }
  

  /* JobCard.css */
@media (max-width: 768px) {
    .job-card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 5px;
        margin: 5px;
        border: 1px solid #e8e8e8;
        border-radius: 8px;
        background-color: #fff;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      }
    .companyLogo {
      padding: 5px 5px;
    } 
    .job-card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .job-card-header-left {
        display: flex;
        align-items: flex-start;
    }
    .job-card-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }      
      .job-card-contact {
        display: flex;
        align-items: center;
        color: #888;
      }      
      .job-card-contact img {
        margin-right: 8px;
      }
  }
  
  