.CvInfoContainer {
    display: flex;
    flex-direction: row;
    flex: 1;
}
.LeftPane {
    flex: 1;
    border: 1px solid blueviolet;
    margin-right: 10px;
    margin-left:29px;
    margin-top: 10px;
}
.RightPane {
    flex: 3;
    padding: 0px 2px 10px 10px;
    border: 1px solid green;
    margin-top: 10px;
    margin-right: 24px;
}
.ColumnLeft {
    width: 30%;
}
.ColumnSecond {
    width: 70%;
}
table {
    font-size: 14px;
}