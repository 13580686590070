/* Dropdown.css */
/* Dropdown.css */
.dropdown {
    /*border: 1px solid rgba(242, 109, 33, 1);*/
    border-radius: 4px;
    padding: 8px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
}
.dropdown-icon {
    color:rgba(242, 109, 33, 1);
    transition: transform 0.3s;
}
.dropdown-open {
    border-color: #ff4d4f;
}
.dropdown-icon-rotate {
    color:rgba(242, 109, 33, 1);
    transform: rotate(180deg);
}
.dropdown-header {
    color:rgba(242, 109, 33, 1);
    font-weight: bold;
}

.dropdown-menu {
    background-color: #f5f5f5;
    border-top: 1px solid #e8e8e8;
    max-height: 200px;
    overflow-y: auto;
}

.dropdown-item {
    display: flex;
    justify-content: space-between;
    padding: 8px 16px;
    cursor: pointer;
    position: relative;
}

.dropdown-item:hover {
    background-color: #e6f7ff;
}

.dropdown-item.active {
    color:rgba(242, 109, 33, 1);
    font-weight: bold;
    background-color: #fff7e6;
}

.dropdown-item.active::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 4px;
    background-color:rgba(242, 109, 33, 1);
}
.heart {
    color: rgba(242, 109, 33, 1);
}
.icon {
    float: right;
    color: rgba(242, 109, 33, 1);
}
/* Responsive adjustments */
@media (max-width: 600px) {
    .dropdown {
        padding: 6px 10px;
    }

    .dropdown-item {
        padding: 6px 14px;
    }
}
